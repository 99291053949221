function addCookie(name, val) {
    document.cookie = name + "=" + val + "; expires=Thu, 18 Dec 3080 12:00:00 UTC";
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1);
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return "";
}

function removeCookie(cname) {

    document.cookie = cname + "=" + "remove" + "; expires=Thu, 18 Dec 1999 12:00:00 UTC";
}

function createElem(type, text, parent, id, classes) {

    const newElem = document.createElement(type);
    newElem.innerText = text;

    if (parent != "")
        parent.appendChild(newElem);

    if (id != "")
        newElem.id = id;

    for (const className of classes)
        newElem.classList.add(className);

    return newElem;
}

// document.getElementById("move-screen-up").addEventListener("click", () => {
//     // window.scrollTo(0, 0);
//     document.getElementById("canvas-holder").getElementsByTagName("canvas")[0].scrollIntoView();

// });

// document.getElementById("move-screen-down").addEventListener("click", () => {
//     document.getElementById("armor-values").scrollIntoView();
// });

// const onScroll = (e) => {
//     // console.log(document.body.scrollTop);
//     // console.log(window.pageYOffset);
//     if (window.pageYOffset < 650) {
//         document.getElementById("move-screen-up").classList.add("fade-out");
//         document.getElementById("move-screen-down").classList.remove("fade-out");
//     } else {
//         document.getElementById("move-screen-up").classList.remove("fade-out");
//         document.getElementById("move-screen-down").classList.add("fade-out");
//     }
// };

document.getElementById("toggle-trims").addEventListener("click", () => {

    document.getElementById("trim-sets").classList.toggle("make-trims-visible");
    document.getElementById("delete-button").classList.toggle("delete-button-visible");

    if (document.getElementById("trim-sets").classList.contains("make-trims-visible"))
        document.getElementById("trim-sets").scrollIntoView();

    // document.getElementById("trim-sets").style.left = "-55px";
    // document.getElementById("trim-sets").style.zIndex = 10;
});

// onScroll();

// document.addEventListener("scroll", onScroll);

// const getColorCanvas = document.createElement('canvas');

// async function getUVMapValue(u, v, imgUrl) {

//     const img = document.createElement('img');

//     await img.addEventListener("load", () => {

//         const x = u * img.width;
//         const y = v * img.height;

//         getColorCanvas.width = img.width;
//         getColorCanvas.height = img.height;

//         const ctx = getColorCanvas.getContext('2d');

//         ctx.drawImage(img, 0, 0);

//         const pixelData = ctx.getImageData(x, y, 1, 1).data;

//         const color = `rgba(${pixelData[0]}, ${pixelData[1]}, ${pixelData[2]}, ${pixelData[3]})`;

//         // console.log("color: ", color);

//         return color;

//     });

//     img.src = imgUrl;
// }

// ------------------------------------------------------------

function addCalcChanceToFindFuncs() {
    
    const allTrimInfos = document.getElementsByClassName("more-trim-info"); 

    for (const trimInfo of allTrimInfos) {
        
        const input = trimInfo.getElementsByTagName("input")[0];

        console.log(input.chance);
    }
}

addCalcChanceToFindFuncs();